const _ = {
  find: require('lodash/find'),
  pick: require('lodash/pick'),
};

import * as React from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query, Mutation, MutationFn } from 'react-apollo';
import Helmet from 'react-helmet';
import { DataProxy } from 'apollo-cache';

import { routes } from 'inkp-routes/public';
import Input from 'inkp-components/dist/Components/Input';
import Button from 'inkp-components/dist/Components/Button';
import { validateCreationParams } from 'inkp-user-sdk/user';

import { StateContainer, Subscribe } from 'infra-frontend/helpers/apollo';

import { FieldError } from '../../interfaces';
import AccountLayout from '../../layout/account';
import { CURRENT_USER_QUERY, CURRENT_USER_FRAGMENT, updateCurrentUser, CurrentUserData } from '../../util/login';
import { findServiceError } from '../../util/apollo';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes, GTMEvents } from '../../interfaces/GTM';

const REGISTER_MUTATION = gql`
  mutation Register($email: String!, $name: String!, $password: String!) {
    register(userData: { email: $email, name: $name, password: $password }) {
      ...CurrentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

interface State {
  fields: {
    name: string;
    email: string;
    password: string;
  };
  showPassword: boolean;
  errors: FieldError[];
  registerError: string;
}

class RegisterState extends StateContainer {
  initialState = {
    fields: {
      name: '',
      email: '',
      password: '',
    },
    showPassword: false,
    registerError: '',
    errors: [],
  };

  shape = `
    {
      fields {
        name
        email
        password
      }
      showPassword
      registerError
      errors {
        valid
        message
        field
      }
    }
  `;

  // Defining event handler to update the state
  updateField = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (field === 'email') {
      value = value.toLowerCase();
    }

    const oldFieldError = _.find(this.state.errors, { field });
    const fields = Object.assign({}, this.state.fields, { [field]: value });
    const newFieldError = _.find(validateCreationParams(fields), { field });

    let errors = this.state.errors;
    if (oldFieldError && (!newFieldError || newFieldError.message !== oldFieldError.message)) {
      errors = this.state.errors.map((error: { valid: boolean; message: string; field: string }) => {
        if (error.field !== field) return error;
        return Object.assign({}, error, { valid: true });
      });
    }

    this.setState({ fields, errors });
  };

  togglePassword = () => () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  setErrors = (errors: FieldError[]) => {
    this.setState({ errors, registerError: '' });
  };

  setRegisterError = (error: string) => {
    this.setState({ registerError: error });
  };
}

export default class RegisterPage extends React.Component<RouteComponentProps> {
  registerUpdate = () => (cache: DataProxy, { data }: { data: { register: CurrentUserData } }) => {
    updateCurrentUser(cache, data.register);
    GTM.push(GTMTypes.USER);
    GTM.fire(GTMEvents.ACCOUNT_SIGNUP);
  };

  completeRegistration = () => () => {
    this.props.history.push(routes.app.base);
  };

  submit = (state: State, setErrors: (errors: FieldError[]) => void, register: MutationFn) => (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const validationResult = validateCreationParams(state.fields);
    if (validationResult.length > 0) {
      return setErrors(validationResult);
    }
    register({
      variables: _.pick(state.fields, 'name', 'password', 'email'),
    });
  };

  registerError = (setErrors: (errors: FieldError[]) => void, setRegisterError: (error: string) => void) => (
    error: any
  ) => {
    const serviceError = findServiceError(error);
    if (serviceError && serviceError.extensions && serviceError.extensions.code === 'TAKEN_EMAIL') {
      return setErrors([{ valid: false, field: 'email', message: serviceError.data.message }]);
    }
    setRegisterError('Error encountered');
  };

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    return (
      <Query query={CURRENT_USER_QUERY}>
        {({ data: userData }: any) => {
          if (userData.currentUser!.role !== 'ANON') return <Redirect to={routes.app.base} />;
          return (
            <Subscribe to={[RegisterState]} namespace="Register">
              {({ state, updateField, togglePassword, setErrors, setRegisterError }) => (
                <Mutation
                  mutation={REGISTER_MUTATION}
                  update={this.registerUpdate()}
                  onCompleted={this.completeRegistration()}
                  onError={this.registerError(setErrors, setRegisterError)}
                >
                  {(register: MutationFn) => {
                    const nameError = _.find(state.errors, { field: 'name' });
                    const emailError = _.find(state.errors, { field: 'email' });
                    const passwordError = _.find(state.errors, { field: 'password' });
                    return (
                      <AccountLayout>
                        <Helmet>
                          <title>Inkpop - Register</title>
                        </Helmet>
                        <div>
                          Already have an account? <Link to={routes.app.account.signIn}>Sign In</Link>
                          <div className="mt-2">
                            <h1 className="fs-xl color-coral fw-extra-bold">Create Account</h1>
                          </div>
                          <form className="mt-2 w-full" onSubmit={this.submit(state, setErrors, register)}>
                            <Input
                              label="Full Name"
                              className="w-full"
                              value={state.fields.name}
                              onChange={updateField('name')}
                              error={nameError && !nameError.valid}
                              helper={nameError ? nameError.message : ''}
                              helperColor={nameError && !nameError.valid ? 'red' : 'green'}
                            />
                            <Input
                              label="Email"
                              className="w-full mt-1p5"
                              inputType="email"
                              value={state.fields.email}
                              onChange={updateField('email')}
                              error={emailError && !emailError.valid}
                              helper={emailError ? emailError.message : ''}
                              helperColor={emailError && !emailError.valid ? 'red' : 'green'}
                            />
                            <Input
                              label="Password"
                              className="w-full mt-1p5"
                              inputType={state.showPassword ? 'text' : 'password'}
                              value={state.fields.password}
                              onChange={updateField('password')}
                              onRightIconClick={togglePassword()}
                              rightIcon={state.showPassword ? 'eye' : 'eye-off'}
                              error={passwordError && !passwordError.valid}
                              helper={passwordError ? passwordError.message : state.registerError}
                              helperColor={passwordError && !passwordError.valid ? 'red' : 'green'}
                            />
                            <Button buttonType="submit" className="w-full mt-1p5">
                              Register
                            </Button>
                          </form>
                          <div className="mt-2 ta-center fs-xs">
                            By registering, you confirm that you've read and accepted our&nbsp;
                            <Link to={routes.app.termsOfService} target="_blank">Terms of Use</Link>
                            &nbsp;and&nbsp;
                            <Link to={routes.app.privacyPolicy} target="_blank">Privacy Policy</Link>
                            .
                          </div>
                        </div>
                      </AccountLayout>
                    );
                  }}
                </Mutation>
              )}
            </Subscribe>
          );
        }}
      </Query>
    );
  }
}
